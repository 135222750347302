// MMC || MAGIC || AEREOVIAGEM
var projeto = "SKY";
var producao = true;
var usaPacote = true;
var usaBeto = false;
export const USA_AEREO = true;
export const USA_LAMINAS = false;
export const SERVICOS_OBRIGATORIOS = false; // true para MMC - traz os serviços vinculados ao pacote como obrigatório
export const USA_CARRO = false; // Exclusivo MMC
var somenteLinkCartao = false;
var idioma = "pt"; // Padrão de idioma sempre pt. Somente BRASIL OPERADORA que não usa pt.
var usalinktarifario = false;
var usalinkblackfriday = "";
var usasomenteida = false;
switch (projeto) {
  case "Genius":
    var logo = "/assets/logo/genius-logo.svg";
    var logoLight = "/assets/logo/genius-light-logo.svg";
    var favicon = "/assets/logo/AS-Genius.svg";
    var endereco = "Av. Brasil, 1345, 4º Andar - Centro, Foz do Iguaçu - PR <br> (45) 3521-8599";
    var frase = "We Are Genius";
    var frasehome = "E a tecnologia corre em nossas veias";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/genius/loginBackground.png";
    var slider = "/assets/images/genius/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://api.geniusws.com.br") : (api = "https://localhost:44369");

    break;

  case "MMC":
    usaBeto = true;
    var logo = "/assets/logo/logo-nova-colorida.svg";
    var logoLight = "/assets/logo/logo-branca-sem-borda.png";
    var favicon = "/assets/logo/favicon-mmc-light.svg";
    var endereco = "Rua Marechal Floriano Peixoto, 1455 - Centro, Foz do Iguaçu - PR, 85851-020 - PR - (45)3521-7508";
    var frase = "Descubra o Brasil";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "https://www.instagram.com/mmcturismo/";
    var face = "https://www.facebook.com/receptivommc";
    var portfolio = "https://produto.mmcturismo.com.br/site/";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/mmc/backgroundImage.jpeg";
    var slider = "/assets/images/mmc/cataratas-mmc-turismo-receptivo.jpg";
    var sliderDesktop = "/assets/images/mmc/cataratas-mmc-turismo-receptivo-desktop.jpg";
    var api = "";
    var apiaereo = "";
    var apihotel = "";
    var linktarifario = "https://paquetes.mmcturismo.com.br/";
    usalinktarifario = true;
    producao ? (api = "https://api.mmcturismo.com") : (api = "http://localhost:62672");
    producao ? (apiaereo = "https://aereo.mmcturismo.com") : (apiaereo = "http://localhost:62672");
    producao ? (apihotel = "https://hotel.mmcturismo.com") : (apihotel = "http://localhost:62672");
    usalinkblackfriday = "";
    break;
  case "BRASIL":
    var logo = "/assets/logo/logo-brasil.png";
    var logoLight = "/assets/logo/logo-brasil.png";
    var favicon = "/assets/logo/logo-brasil.png";
    var endereco = "";
    var frase = "Brasil Operadora";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = false; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/brasil/loginBackground.webp";
    var slider = "./assets/images/brasil/slide.jpg";
    var sliderDesktop = "/assets/images/brasil/slide.jpg";
    var api = "";
    var apiaereo = "";
    var apihotel = "";
    producao ? (api = "https://apibrasil.geniusws.com.br") : (api = "https://localhost:44369");
    producao ? (apiaereo = "https://apibrasil.geniusws.com.br") : (apiaereo = "https://localhost:44369");
    producao ? (apihotel = "https://apibrasil.geniusws.com.br") : (apihotel = "https://localhost:44369");
    usaPacote = true;
    idioma = "es"
    break;
  case "KANGAROO":
    var logo = "/assets/logo/logo-kangaroo.png";
    var logoLight = "/assets/logo/logo-kangaroo.png";
    var favicon = "/assets/logo/logo-kangaroo.png";
    var endereco = "";
    var frase = "Kangaroo Tours";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/kangaroo/backgroundImage.png";
    var slider = "./assets/images/kangaroo/slide.jpg";
    var sliderDesktop = "/assets/images/mmc/slide.jpg";
    var api = "";
    var apiaereo = "";
    var apihotel = "";
    producao ? (api = "https://apikangaroo.geniusws.com.br") : (api = "https://localhost:44369");
    producao ? (apiaereo = "https://apikangaroo.geniusws.com.br") : (apiaereo = "https://localhost:44369");
    producao ? (apihotel = "https://apikangaroo.geniusws.com.br") : (apihotel = "https://localhost:44369");
    usaPacote = false;
    break;
  case "TNOPERADORA":
    var logo = "/assets/logo/logo-tnoperadora.png";
    var logoLight = "/assets/logo/logo-tnoperadora.png";
    var favicon = "/assets/logo/logo-tnoperadora.png";
    var endereco = "";
    var frase = "TN Operadora";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = false; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/tnoperadora/fundo_preto.png";
    var slider = "./assets/images/tnoperadora/fundo_preto.png";
    var sliderDesktop = "./assets/images/tnoperadora/fundo_preto.png";
    var api = "";
    var apiaereo = "";
    var apihotel = "";
    producao ? (api = "https://apitnoperadora.geniusws.com.br") : (api = "https://localhost:44369");
    producao ? (apiaereo = "https://apitnoperadora.geniusws.com.br") : (apiaereo = "https://localhost:44369");
    producao ? (apihotel = "https://apitnoperadora.geniusws.com.br") : (apihotel = "https://localhost:44369");

    break;
  case "SKY":
    var logo = "/assets/logo/Logo Sky Line.jpeg";
    var logoLight = "/assets/logo/Logo Sky Line.jpeg";
    var favicon = "/assets/logo/Logo Sky Line.jpeg";
    var endereco = "Avenida Getúlio Vargas 14 - Itaituba, PA";
    var frase = "Sky Line Operadora";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = false; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/sky/Logo Sky Line.jpeg";
    var slider = "./assets/images/sky/slide-sky.jpg";
    var sliderDesktop = "./assets/images/sky/slide-sky.jpg";
    var api = "";
    var apiaereo = "";
    var apihotel = "";
    usasomenteida = true;
    producao ? (api = "https://apiskyline.geniusws.com.br") : (api = "http://localhost:62672/");
    producao ? (apiaereo = "https://apiskyline.geniusws.com.br") : (apiaereo = "http://localhost:62672/");
    producao ? (apihotel = "https://apiskyline.geniusws.com.br") : (apihotel = "http://localhost:62672/");

    break;
  case "DELIVERY":
    var logo = "/assets/logo/logo_Delivery_Tour.jpeg";
    var logoLight = "/assets/logo/logo_Delivery_Tour.jpeg";
    var favicon = "/assets/logo/logo_Delivery_Tour.jpeg";
    var endereco = "R. Victorino Cardoso, 220, Edf. Centro Comercial Jardins Sala 03, Jardim Cambiri, Vitoria – Espirito Santo";
    var frase = "Delivery Tour Operadora";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/deliverytour/logo-deliverytour.png";
    var slider = "./assets/images/deliverytour/slide-deliverytour.jpg";
    var sliderDesktop = "./assets/images/deliverytour/slide-deliverytour.jpg";
    var api = "";
    var apiaereo = "";
    var apihotel = "";
    producao ? (api = "https://apideliverytour.geniusws.com.br") : (api = "http://localhost:62672/");
    producao ? (apiaereo = "https://apideliverytour.geniusws.com.br") : (apiaereo = "http://localhost:62672/");
    producao ? (apihotel = "https://apideliverytour.geniusws.com.br") : (apihotel = "http://localhost:62672/");

    break;
  case "USETRAVEL":
    var logo = "/assets/logo/logo-usetravel.svg";
    var logoLight = "/assets/logo/logo-usetravel.svg";
    var favicon = "/assets/logo/logo-usetravel.svg";
    var endereco = "Rua Quinze de Novembro, 1608, Casa 28, Vila Dubus, Presidente Prudente, São Paulo";
    var frase = "Use Travel";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = false; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/usetravel/logo-usetravel.png";
    var slider = "./assets/images/usetravel/slide.jpg";
    var sliderDesktop = "./assets/images/usetravel/slide.jpg";
    var api = "";
    var apiaereo = "";
    var apihotel = "";
    producao ? (api = "https://apiusetravel.geniusws.com.br") : (api = "https://localhost:44369");
    producao ? (apiaereo = "https://apiusetravel.geniusws.com.br") : (apiaereo = "https://localhost:44369");
    producao ? (apihotel = "https://apiusetravel.geniusws.com.br") : (apihotel = "https://localhost:44369");
    usaPacote = false;
    somenteLinkCartao = true;
    break;

  case "AEREOVIAGEM":
    var logo = "/assets/logo/logotipo-aereo-viagem-v002.png";
    var logoLight = "/assets/logo/logotipo-aereo-viagem-v002-negativo.png";
    var favicon = "/assets/logo/logotipo-aereo-viagem-v002-negativo.png";
    var endereco = "";
    var frase = "Aéreo Viagem";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/point/loginBackground.png";
    var slider = "./assets/images/point/loginBackground.png";
    var api = "";
    var apiaereo = "";
    var apihotel = "";
    producao ? (api = "https://api.agente.tur.br") : (api = "https://localhost:44369");
    producao ? (apiaereo = "https://aereo.agente.tur.br") : (apiaereo = "https://localhost:44369");
    producao ? (apihotel = "https://hotel.agente.tur.br") : (apihotel = "https://localhost:44369");
    somenteLinkCartao = true;
    break;

  case "MAGIC":
    var logo = "../../../assets/logo/Magic-Way-Tours.png";
    var logoLight = "../../../assets/logo/magicway-branca.png";
    var favicon = "../../../assets/logo/magicway-branca-menor.png";
    var endereco = "Avenida Iguaçu, 2636 <br>Água Verde. Curitiba. CEP: 80240-030";
    var frase = "Embarque nessa com a gente!";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "https://magicway.com.br/";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/magic/backgroundImagemagic.jpg";
    var slider = "../../../assets/images/magic/fd-magic.png";
    var api = "";
    var apiaereo = "";
    var apihotel = "";
    var linktarifario = "";
    producao ? (api = "https://apimagicway.geniusws.com.br") : (api = "https://localhost:44369");
    producao ? (apiaereo = "https://apimagicway.geniusws.com.br") : (apiaereo = "https://localhost:44369");
    producao ? (apihotel = "https://apimagicway.geniusws.com.br") : (apihotel = "https://localhost:44369");
    break;

  case "ARK":
    var logo = "/assets/logo/genius-logo.svg";
    var logoLight = "/assets/logo/genius-light-logo.svg";
    var favicon = "/assets/logo/AS-Genius.svg";
    var endereco = "Av. Brasil, 1345, 4º Andar - Centro, Foz do Iguaçu - PR <br> (45) 3521-8599";
    var frase = "We Are Genius";
    var frasehome = "E a tecnologia corre em nossas veias";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/genius/loginBackground.png";
    var slider = "/assets/images/genius/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://api.ark.tur.br") : (api = "https://localhost:44369");
    break;

  case "POINT":
    var logo = "/assets/logo/point-logo.png";
    var logoLight = "/assets/logo/point-light-logo.png";
    var favicon = "/assets/logo/AS-point.ico";
    var endereco = "Tv. Cristiano Weirich, 91 - Sala 3 - Centro, Foz do Iguaçu - PR, 85851-140<br> (45) 3523-0007";
    var frase = "POINT TUR ";
    var frasehome = "Viajar com a POINT TUR é colecionar momentos de toda uma vida!";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/point/loginBackground.png";
    var slider = "/assets/images/point/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://api.pointtur.com.br") : (api = "https://localhost:44369");
    producao ? (apiaereo = "https://api.pointtur.com.br") : (apiaereo = "https://localhost:44369");
    producao ? (apihotel = "https://api.pointtur.com.br") : (apihotel = "https://localhost:44369");

    break;

  case "OIKOS":
    var logo = "/assets/logo/oikos-logo.png";
    var logoLight = "/assets/logo/oikos-logo-light.png";
    var favicon = "/assets/logo/oikos-logo-light.png";
    var endereco = "R. Ouro Fino, 395 – Sala 702 – Cruzeiro, Belo Horizonte – MG, 30310-110<br>  (31) 3283-5777/(31) 9614-9354";
    var frase = "Oikos Travel";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://api.whatsapp.com/send?phone=553196149354";
    var insta = "https://www.instagram.com/oikostravel/";
    var face = "https://www.facebook.com/oikostravelbh";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/oikos/loginBackground.png";
    var slider = "/assets/images/oikos/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://apioikos.geniusws.com.br") : (api = "https://localhost:44369");

    break;

  case "ALLTOUR":
    var logo = "/assets/logo/logo-alltour.png";
    var logoLight = "/assets/logo/logo_alltour_branco.png";
    var favicon = "/assets/logo/logo_alltour_branco.png";
    var endereco = "";
    var frase = "Alltour";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5571982666068";
    var insta = "https://www.instagram.com/alltourgroup/";
    var face = "https://www.facebook.com/AlltourBA";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/alltour/loginBackground.png";
    var slider = "/assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://apialltour.comprefacil.tur.br") : (api = "https://localhost:44369");

    break;

  case "ANAUE":
    var logo = "/assets/logo/logo-anaue.png";
    var logoLight = "/assets/logo/logo-anaue-branco.png";
    var favicon = "/assets/logo/logo-anaue-branco.png";
    var endereco = "";
    var frase = "Anauê";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5584991492484";
    var insta = "https://www.instagram.com/anauereceptivo/";
    var face = "https://www.facebook.com/AnaueReceptivo";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/alltour/loginBackground.png";
    var slider = "/assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://apianaue.comprefacil.tur.br") : (api = "https://localhost:44369");

    break;

  case "BROCKER":
    var logo = "/assets/logo/logo-brocker.png";
    var logoLight = "/assets/logo/logo-brocker.png";
    var favicon = "/assets/logo/logo-brocker.png";
    var endereco = "";
    var frase = "Brocker Turismo";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5554981707339";
    var insta = "https://www.instagram.com/brockerturismo/";
    var face = "https://www.facebook.com/brocker.turismo";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/alltour/loginBackground.png";
    var slider = "/assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://apibrocker.ark.tur.br") : (api = "https://localhost:44369");

    break;

  case "LUCK":
    var logo = "/assets/logo/logo-luck.png";
    var logoLight = "/assets/logo/logo-luck.png";
    var favicon = "/assets/logo/logo-luck.png";
    var endereco = "";
    var frase = "Luck Receptivo";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5582991279524";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/alltour/loginBackground.png";
    var slider = "/assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://apiluck.ark.tur.br") : (api = "https://localhost:44369");

    break;

  case "MARTUR":
    var logo = "/assets/logo/logo-martur.png";
    var logoLight = "/assets/logo/logo-martur.png";
    var favicon = "/assets/logo/logo-martur.png";
    var endereco = "";
    var frase = "Martur Viagens e Turismo";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5581991639375";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/alltour/loginBackground.png";
    var slider = "/assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://apimartur.ark.tur.br") : (api = "https://localhost:44369");

    break;

  case "NETTOUR":
    var logo = "/assets/logo/logo-nettour.png";
    var logoLight = "/assets/logo/logo-nettour.png";
    var favicon = "/assets/logo/logo-nettour.png";
    var endereco = "";
    var frase = "Nettour Viagens e Receptivo";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5585999314423";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/alltour/loginBackground.png";
    var slider = "/assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://apinettour.ark.tur.br") : (api = "https://localhost:44369");

    break;

  case "TAIPE":
    var logo = "/assets/logo/logo-taipe.png";
    var logoLight = "/assets/logo/logo-taipe.png";
    var favicon = "/assets/logo/logo-taipe.png";
    var endereco = "";
    var frase = "Taipe Viagens e Turismo";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5573988020339";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/alltour/loginBackground.png";
    var slider = "/assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://apitaipe.ark.tur.br") : (api = "https://localhost:44369");

    break;

  case "BONITOWAY":
    var logo = "/assets/logo/logo-bonitoway.png";
    var logoLight = "/assets/logo/logo-bonitoway.png";
    var favicon = "/assets/logo/logo-bonitoway.png";
    var endereco = "";
    var frase = "Bonito Way Agência de Viagem";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5567984691186";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/alltour/loginBackground.png";
    var slider = "/assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://apibonitoway.ark.tur.br") : (api = "https://localhost:44369");

    break;

  case "LUCKJPA":
    var logo = "/assets/logo/logo-luck.png";
    var logoLight = "/assets/logo/logo-luck.png";
    var favicon = "/assets/logo/logo-luck.png";
    var endereco = "";
    var frase = "Luck Receptivo";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5583987459949";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/alltour/loginBackground.png";
    var slider = "/assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://apiluckjpa.ark.tur.br") : (api = "https://localhost:44369");

    break;

  case "COSTAAZUL":
    var logo = "/assets/logo/logo-costaazul.png";
    var logoLight = "/assets/logo/logo-costaazul.png";
    var favicon = "/assets/logo/logo-costaazul.png";
    var endereco = "";
    var frase = "CostaAzul";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5582991378394";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/alltour/loginBackground.png";
    var slider = "/assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://apicostaazul.ark.tur.br") : (api = "https://localhost:44369");

    break;

  case "INTERTOURING":
    var logo = "/assets/logo/logo-intertouring.png";
    var logoLight = "/assets/logo/logo-intertouring.png";
    var favicon = "/assets/logo/logo-intertouring.png";
    var endereco = "";
    var frase = "Intertouring Receptivo";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5521976411306";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/alltour/loginBackground.png";
    var slider = "/assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://apiintertouring.ark.tur.br") : (api = "https://localhost:44369");

    break;

  case "URBEN":
    var logo = "/assets/logo/logo-urben.png";
    var logoLight = "/assets/logo/logo-urben.png";
    var favicon = "/assets/logo/logo-urben.png";
    var endereco = "";
    var frase = "Urben Turismo";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5511991956692";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/alltour/loginBackground.png";
    var slider = "/assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://apiurben.ark.tur.br") : (api = "https://localhost:44369");

    break;

  case "VIPMAR":
    var logo = "/assets/logo/logo-vipmar.png";
    var logoLight = "/assets/logo/logo-vipmar.png";
    var favicon = "/assets/logo/logo-vipmar.png";
    var endereco = "";
    var frase = "Vipmar";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5547999750594";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "/assets/images/alltour/loginBackground.png";
    var slider = "/assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://apivipmar.ark.tur.br") : (api = "https://localhost:44369");

    break;

  case "CAPIXABA":
    var logo = "./assets/logo/logo-capixaba.png";
    var logoLight = "./assets/logo/logo-capixaba.png";
    var favicon = "./assets/logo/logo-capixaba.png";
    var endereco = "";
    var frase = "Capixaba";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5527996063500";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://ark.tur.br/capixaba/api") : (api = "https://localhost:44369");

    break;

  case "PRESTHEZA":
    var logo = "./assets/logo/logo-prestheza.png";
    var logoLight = "./assets/logo/logo-prestheza.png";
    var favicon = "./assets/logo/logo-prestheza.png";
    var endereco = "";
    var frase = "Prestheza";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5561981163786";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://ark.tur.br/prestheza/api") : (api = "https://localhost:44369");

    break;

  case "INBUZIOS":
    var logo = "./assets/logo/logo-inbuzios.png";
    var logoLight = "./assets/logo/logo-inbuzios.png";
    var favicon = "./assets/logo/logo-inbuzios.png";
    var endereco = "";
    var frase = "Inbuzios";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5522974047531";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://ark.tur.br/inbuzios/api") : (api = "https://localhost:44369");

    break;

  case "TRILHASDEMINAS":
    var logo = "./assets/logo/logo-trilhasdeminas.png";
    var logoLight = "./assets/logo/logo-trilhasdeminas.png";
    var favicon = "./assets/logo/logo-trilhasdeminas.png";
    var endereco = "";
    var frase = "Trilhas De Minas";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5531987441601";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://ark.tur.br/trilhasdeminas/api") : (api = "https://localhost:44369");

    break;

  case "IMPACTOTOUR":
    var logo = "./assets/logo/logo-impactotour.png";
    var logoLight = "./assets/logo/logo-impactotour.png";
    var favicon = "./assets/logo/logo-impactotour.png";
    var endereco = "";
    var frase = "Impacto Tour";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5567992062282";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://ark.tur.br/impactotour/api") : (api = "https://localhost:44369");

    break;

  case "SERRAVERDE":
    var logo = "./assets/logo/logo-serraverde.png";
    var logoLight = "./assets/logo/logo-serraverde.png";
    var favicon = "./assets/logo/logo-serraverde.png";
    var endereco = "";
    var frase = "Serra Verde Express";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5567992062282";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://ark.tur.br/serraverde/api") : (api = "https://localhost:44369");

    break;

  case "ATALAIA":
    var logo = "./assets/logo/logo-atalaia.png";
    var logoLight = "./assets/logo/logo-atalaia.png";
    var favicon = "./assets/logo/logo-atalaia.png";
    var endereco = "";
    var frase = "Atalaia";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5581999797232";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://ark.tur.br/atalaia/api") : (api = "https://localhost:44369");

    break;

  case "GEKOS":
    var logo = "./assets/logo/logo-gekos.png";
    var logoLight = "./assets/logo/logo-gekos.png";
    var favicon = "./assets/logo/logo-gekos.png";
    var endereco = "";
    var frase = "Gekos";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5598981190456";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://ark.tur.br/gekos/api") : (api = "https://localhost:44369");

    break;

  case "TAGUATUR":
    var logo = "./assets/logo/logo-taguatur.png";
    var logoLight = "./assets/logo/logo-taguatur.png";
    var favicon = "./assets/logo/logo-taguatur.png";
    var endereco = "";
    var frase = "Taguatur";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5598981265666";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://ark.tur.br/taguatur/api") : (api = "https://localhost:44369");

    break;

  case "BRAZILCONNECTION":
    var logo = "./assets/logo/logo-brazilconnection.png";
    var logoLight = "./assets/logo/logo-brazilconnection.png";
    var favicon = "./assets/logo/logo-brazilconnection.png";
    var endereco = "";
    var frase = "Brazil Connection";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5521982882369";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://ark.tur.br/brazilconnection/api") : (api = "https://localhost:44369");

    break;

  case "GIORDANI":
    var logo = "./assets/logo/logo-giordani.png";
    var logoLight = "./assets/logo/logo-giordani.png";
    var favicon = "./assets/logo/logo-giordani.png";
    var endereco = "";
    var frase = "Giordani Turismo";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5554999303897";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://ark.tur.br/giordani/api") : (api = "https://localhost:44369");

    break;

  case "LUCKSALVADOR":
    var logo = "./assets/logo/logo-luck.png";
    var logoLight = "./assets/logo/logo-luck.png";
    var favicon = "./assets/logo/logo-luck.png";
    var endereco = "";
    var frase = "Luck Receptivo - Salvador";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=558391213440";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://ark.tur.br/lucksalvador/api") : (api = "https://localhost:44369");

    break;

  case "LUCKRECIFE":
    var logo = "./assets/logo/logo-luck.png";
    var logoLight = "./assets/logo/logo-luck.png";
    var favicon = "./assets/logo/logo-luck.png";
    var endereco = "";
    var frase = "Luck Receptivo - Recife";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=558133666262";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://ark.tur.br/luckrecife/api") : (api = "https://localhost:44369");

    break;
  case "TACK":
    var logo = "./assets/logo/logo-tack.jpg";
    var logoLight = "./assets/logo/logo-tack.jpg";
    var favicon = "./assets/logo/logo-tack.jpg";
    var endereco = "";
    var frase = "Tack Turismo";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "https://web.whatsapp.com/send?phone=5511945297878";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://ark.tur.br/tack/api") : (api = "https://localhost:44369");

    break;

  case "QUALITY":
    var logo = "./assets/logo/logo-quality.png";
    var logoLight = "./assets/logo/logo-quality.png";
    var favicon = "./assets/logo/logo-quality.png";
    var endereco = "";
    var frase = "Quality Travel";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    var linktarifario = "";
    producao ? (api = "https://ark.tur.br/quality/api") : (api = "https://localhost:44369");

    break;
  case "AEXTOUR":
    var logo = "./assets/logo/logo-aextour.png";
    var logoLight = "./assets/logo/logo-aextour.png";
    var favicon = "./assets/logo/logo-aextour.png";
    var endereco = "";
    var frase = "Aex Tour";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/aextour/api") : (api = "https://localhost:44369");

    break;

  case "ATTRAVEL":
    var logo = "./assets/logo/logo-attravel.png";
    var logoLight = "./assets/logo/logo-attravel.png";
    var favicon = "./assets/logo/logo-attravel.png";
    var endereco = "";
    var frase = "attravel";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/attravel/api") : (api = "https://localhost:44369");

    break;

  case "4HATS":
    var logo = "./assets/logo/logo-4hats.png";
    var logoLight = "./assets/logo/logo-4hats.png";
    var favicon = "./assets/logo/logo-4hats.png";
    var endereco = "";
    var frase = "4 Hats";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/4hats/api") : (api = "https://localhost:44369");

    break;

  case "NITESTRAVEL":
    var logo = "./assets/logo/logo-nitestravel.png";
    var logoLight = "./assets/logo/logo-nitestravel.png";
    var favicon = "./assets/logo/logo-nitestravel.png";
    var endereco = "";
    var frase = "nites travel";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/nitestravel/api") : (api = "https://localhost:44369");

    break;

  case "GOLFINHOTOUR":
    var logo = "./assets/logo/logo-golfinhotour.png";
    var logoLight = "./assets/logo/golfinhotour.png";
    var favicon = "./assets/logo/golfinhotour.png";
    var endereco = "";
    var frase = "Golfinho Tour";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://apigolfinhotour.geniusws.com.br") : (api = "https://localhost:44369");

    break;

  case "PTA":
    var logo = "./assets/logo/logo-pta.png";
    var logoLight = "./assets/logo/logo-pta.png";
    var favicon = "./assets/logo/logo-pta.png";
    var endereco = "";
    var frase = "PTA";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/pta/api") : (api = "https://localhost:44369");

    break;

  case "AMDRIVER":
    var logo = "./assets/logo/logo-amdriver.png";
    var logoLight = "./assets/logo/logo-amdriver.png";
    var favicon = "./assets/logo/logo-amdriver.png";
    var endereco = "";
    var frase = "AM DRIVER PREMIUM";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/amdriver/api") : (api = "https://localhost:44369");

    break;

  case "AEXTOUR":
    var logo = "./assets/logo/logo-aextour.png";
    var logoLight = "./assets/logo/logo-aextour.png";
    var favicon = "./assets/logo/logo-aextour.png";
    var endereco = "";
    var frase = "AEXTOUR";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/aextour/api") : (api = "https://localhost:44369");

    break;

  case "5STAR":
    var logo = "./assets/logo/logo-5star.png";
    var logoLight = "./assets/logo/logo-5star.png";
    var favicon = "./assets/logo/logo-5star.png";
    var endereco = "";
    var frase = "5STAR";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/5star/api") : (api = "https://localhost:44369");

    break;

  case "OTIUM":
    var logo = "./assets/logo/logo-otium.png";
    var logoLight = "./assets/logo/logo-otium.png";
    var favicon = "./assets/logo/logo-otium.png";
    var endereco = "";
    var frase = "Otium";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/otium/api") : (api = "https://localhost:44369");

    break;

  case "DCOM":
    var logo = "./assets/logo/logo-dcom.png";
    var logoLight = "./assets/logo/logo-dcom.png";
    var favicon = "./assets/logo/logo-dcom.png";
    var endereco = "";
    var frase = "DCOM";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/dcom/api") : (api = "https://localhost:44369");

    break;

  case "DISTINTOS":
    var logo = "./assets/logo/logo-distintos.png";
    var logoLight = "./assets/logo/logo-distintos.png";
    var favicon = "./assets/logo/logo-distintos.png";
    var endereco = "";
    var frase = "DISTINTOS";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/distintos/api") : (api = "https://localhost:44369");

    break;

  case "PESANTEZ":
    var logo = "./assets/logo/logo-pesantez.png";
    var logoLight = "./assets/logo/logo-pesantez.png";
    var favicon = "./assets/logo/logo-pesantez.png";
    var endereco = "";
    var frase = "PESANTEZ";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/pesantez/api") : (api = "https://localhost:44369");

    break;
  case "JULIATRAVEL":
    var logo = "./assets/logo/logo-juliatravel.png";
    var logoLight = "./assets/logo/logo-juliatravel.png";
    var favicon = "./assets/logo/logo-juliatravel.png";
    var endereco = "";
    var frase = "JULIA TRAVEL";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/juliatravel/api") : (api = "https://localhost:44369");

    break;

  case "LUP":
    var logo = "./assets/logo/logo-lup.png";
    var logoLight = "./assets/logo/logo-lup.png";
    var favicon = "./assets/logo/logo-lup.png";
    var endereco = "";
    var frase = "LUP BEACH VCLUB";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/lup/api") : (api = "https://localhost:44369");

    break;

  case "LIVINGTOURS":
    var logo = "./assets/logo/logo-livingtours.png";
    var logoLight = "./assets/logo/logo-livingtours.png";
    var favicon = "./assets/logo/logo-livingtours.png";
    var endereco = "";
    var frase = "LIVING TOURS";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/livingtours/api") : (api = "https://localhost:44369");

    break;


  case "EUROWELCOME":
    var logo = "./assets/logo/logo-eurowelcome.png";
    var logoLight = "./assets/logo/logo-eurowelcome.png";
    var favicon = "./assets/logo/logo-eurowelcome.png";
    var endereco = "";
    var frase = "EUROWELCOME";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/eurowelcome/api") : (api = "https://localhost:44369");

    break;

  case "MCA":
    var logo = "./assets/logo/logo-mca.png";
    var logoLight = "./assets/logo/logo-mca.png";
    var favicon = "./assets/logo/logo-mca.png";
    var endereco = "";
    var frase = "MCA";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/mca/api") : (api = "https://localhost:44369");

    break;

  case "SANTAEBELLA":
    var logo = "./assets/logo/logo-santaebella.png";
    var logoLight = "./assets/logo/logo-santaebella.png";
    var favicon = "./assets/logo/logo-santaebella.png";
    var endereco = "";
    var frase = "SANTA E BELLA";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/santaebella/api") : (api = "https://localhost:44369");

    break;

  case "GOCITY":
    var logo = "./assets/logo/logo-gocity.png";
    var logoLight = "./assets/logo/logo-gocity.png";
    var favicon = "./assets/logo/logo-gocity.png";
    var endereco = "";
    var frase = "GO CITY";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/gocity/api") : (api = "https://localhost:44369");

    break;

  case "SELECTA":
    var logo = "./assets/logo/logo-selecta.png";
    var logoLight = "./assets/logo/logo-selecta.png";
    var favicon = "./assets/logo/logo-selecta.png";
    var endereco = "";
    var frase = "SELECTA";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/selecta/api") : (api = "https://localhost:44369");

    break;

  case "ELEVATE":
    var logo = "./assets/logo/logo-elevate.png";
    var logoLight = "./assets/logo/logo-elevate.png";
    var favicon = "./assets/logo/logo-elevate.png";
    var endereco = "";
    var frase = "Elevate";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/elevate/api") : (api = "https://localhost:44369");
    break;

  case "EKATOURS":
    var logo = "./assets/logo/logo-ekatours.png";
    var logoLight = "./assets/logo/logo-ekatours.png";
    var favicon = "./assets/logo/logo-ekatours.png";
    var endereco = "";
    var frase = "Ekatours";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/ekatours/api") : (api = "https://localhost:44369");
    break;

  case "ALLREPS":
    var logo = "./assets/logo/logo-allreps.png";
    var logoLight = "./assets/logo/logo-allreps.png";
    var favicon = "./assets/logo/logo-allreps.png";
    var endereco = "";
    var frase = "AllReps Receptivo";
    var frasehome = "Ofereça uma viagem inesquecível aos seus PAX";
    var wwp = "";
    var insta = "";
    var face = "";
    var portfolio = "";
    var tarifario = true; //esconde ou mostra o item de tarifario do menu
    var background = "./assets/images/alltour/loginBackground.png";
    var slider = "./assets/images/alltour/loginBackground.png";
    var api = "";
    producao ? (api = "https://ark.tur.br/allreps/api") : (api = "https://localhost:44369");
    break;
}

export const USA_LINKTARIFARIO = usalinktarifario; //Exclusivo MMC
export const USA_PACOTE = usaPacote; //Exclusivo MMC
export const USA_BETO = usaBeto; //Exclusivo MMC
//Permite apenas link de pagamento;
export const SOMENTE_LINK_PAGAMENTO = somenteLinkCartao;
export const PROJETO = projeto;
export const API = api;
export const LOGO = logo;
export const LOGOLIGHT = logoLight;
export const FAVICONLIGHT = favicon;
export const ENDERECOTELEFONE = endereco;
export const FRASELOGIN = frase;
export const FRASEHOME = frasehome;
export const WPP = wwp;
export const INSTAGRAM = insta;
export const FACEBOOK = face;
export const LINKPORTFOLIO = portfolio;
export const IDIOMA = idioma;
export const LINKTARIFARIO = linktarifario;
export const ESCONDERTARIFARIO = tarifario;
export const LINKBLACKFRIDAY = usalinkblackfriday;
export const BACKGROUNDIMAGE = background;
export const SLIDERHOME = slider;
export const SLIDERHOMEDESKTOP = sliderDesktop;
export const CLIENT_ID = "postman:123";
export const ITENSPORPAGINA = 20;
export const PACOTESPORPAGINA = 9;
export const ITENSORCAMENTO = 20;
export const EMPRESAS = API;
export const GERENCIAMENTO = API;
export const TURISMO = API;
export const TURISMO_AEREO = apiaereo;
export const TURISMO_HOTEL = apihotel;
export const TURISMO_TICKET = API;
export const TURISMO_TRANSFER = API;
export const TURISMO_SEGURO = API;
export const TURISMO_CARRO = API;
export const USASOMENTEIDA = usasomenteida;
